import $ from 'jquery';
import React, { useState } from 'react';

function ContactForm() {
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!name || !city || !email || !message) {
      alert('Please provide all the required fields');
      return;
    }

    setIsSubmitting(true);
    // Use jQuery to submit form data to PHP endpoint
    $.ajax({
      url: 'https://webicater.com/backend/mail.php',
      method: 'POST',
      data: { name, email, city, message },
      success: function(response) {
        //console.log(response);
        setSuccessMessage(response);
        setFormSubmitted(true);
      },
      error: function(error) {
        console.log(error);
        setErrorMessage('Error submitting form data.');
      },
      complete: function() {
        setIsSubmitting(false);
      }
    });

    setTimeout(() => {
      setFormSubmitted(false);
      setName('');
      setCity('');
      setEmail('');
      setMessage('');
    }, 10000);
  };
  

  return (
    <form className="chat" onSubmit={handleSubmit}>

<div className="chat">
  <div className="chat-line">
    <div className="text">Hi, my name is </div>
    <div className="text-xs">My name is</div>
    <div className="input">
      <div className="field-underline" />
      <input type="text" name="name" data-aos-anchor=".connect-brand" data-aos="fade-right" data-aos-offset={300} data-aos-delay={50} value={name} onChange={event => setName(event.target.value)}/>
    </div>
    <div className="text">from </div>
    <div className="text-xs">I'm from</div>
    <div className="input  input-xs">
      <div className="field-underline" />
      <input type="text" name="city" data-aos-anchor=".connect-brand" data-aos="fade-right" data-aos-offset={300} data-aos-delay={100} value={city} onChange={event => setCity(event.target.value)}/>
    </div>
    <div className="text">. I would like to chat with JK </div>
    <div className="text">about</div>
    <div className="text-xs">My project is </div>
    <div className="input input-xs">
      <div className="field-underline" />
      <input type="text" name="text" data-aos-anchor=".connect-brand" data-aos="fade-right" data-aos-offset={300} data-aos-delay={150}  value={email} onChange={event => setEmail(event.target.value)}/>
    </div>
    <div className="text">. You can</div><div className="text">reach me at</div> 
    <div className="text">this email</div>
    <div className="text-xs">You can email me at </div>
    <div className="input ">
      <div className="field-underline" />
      <input type="text" name="text" data-aos-anchor=".connect-brand" data-aos="fade-right" data-aos-offset={300} data-aos-delay={200} value={message} onChange={event => setMessage(event.target.value)} />
    </div>
    <div className="text">.</div>
  </div>
  <button className="chat-submit" type="submit" disabled={isSubmitting || formSubmitted}>
  {isSubmitting ? 'Sending...' : formSubmitted ? <span>Sent <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="check" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"></path></svg></span> : <span>Submit <svg data-name="Layer 1" viewBox="0 0 24 15"><path d="M21.88,12l-7.52,6.24L15,19l9-7.52L15,4l-.65.76L21.88,11H0v1Z" transform="translate(0 -4)"></path></svg></span>}
  {isSubmitting && <span className="loading-spinner"></span>}
</button></div>
<div className="message"></div>
{formSubmitted && <div className="success-message">{successMessage}</div>}
{errorMessage && <div className="error-message">{errorMessage}</div>}


          
</form>
  );
}

export default ContactForm;