import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
function Footer() {
  useEffect(() => {

const preloader = document.createElement('div');
preloader.id = 'preloader';
preloader.innerHTML = '<div class="loader_line"></div>';
document.body.appendChild(preloader);
function iknow_tm_preloader() {
  var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
  var preloader = document.getElementById('preloader');
  if (!isMobile) {
    setTimeout(function () {
      preloader.classList.add('preloaded');
    }, 800);
    setTimeout(function () {
      preloader.remove();
    }, 2000);
  } else {
    preloader.remove();
  }
}

iknow_tm_preloader();

function iknow_tm_my_load() {
  var speed = 500;
  setTimeout(function () {
    iknow_tm_preloader();
  }, speed);
  setTimeout(function () {
    document.body.classList.add('opened');
    AOS.init();
  }, speed + 2000);
}

iknow_tm_my_load();

// custom mouse
// Append cursor elements to the body
const outerCursor = document.createElement('div');
outerCursor.classList.add('mouse-cursor', 'cursor-outer');
document.body.appendChild(outerCursor);

const innerCursor = document.createElement('div');
innerCursor.classList.add('mouse-cursor', 'cursor-inner');
document.body.appendChild(innerCursor);

// Customize cursor
function customCursor() {
  const myCursor = document.querySelector('.mouse-cursor');
  if (myCursor) {
    if (document.body) {
      const e = document.querySelector('.cursor-inner');
      const t = document.querySelector('.cursor-outer');
      let n, i = 0, o = false;
      
      window.onmousemove = function(s) {
        if (!o) {
          t.style.transform = "translate(" + s.clientX + "px, " + s.clientY + "px)";
        }
        e.style.transform = "translate(" + s.clientX + "px, " + s.clientY + "px)";
        n = s.clientY;
        i = s.clientX;
      };

      document.body.addEventListener('mouseenter', function(e) {
        const target = e.target;
        if (target.matches('a, .cavani_tm_topbar .trigger, .brand-service, .cursor-pointer')) {
          e.classList.add('cursor-hover');
          t.classList.add('cursor-hover');
        }
      });

      document.body.addEventListener('mouseleave', function(e) {
        const target = e.target;
        if (target.matches('a, .cavani_tm_topbar .trigger, .brand-service, .cursor-pointer')) {
          if (!target.closest('.cursor-pointer')) {
            e.classList.remove('cursor-hover');
            t.classList.remove('cursor-hover');
          }
        }
      });

      e.style.visibility = 'visible';
      t.style.visibility = 'visible';
    }
  }
}
if (window.innerWidth <= 1024) {
  customCursor();
}
const burger = document.querySelector('.burger');
    const navbarDesktop = document.querySelector('.navbar--desktop');
    const body = document.querySelector('body');
    const desktopNavLinks = document.querySelectorAll('.navbar--desktop a');
    burger.addEventListener('click', function() {   
      navbarDesktop.classList.toggle('show');
      burger.classList.toggle('clicked');
      body.classList.toggle('overflow-hidden');
    });
    
    desktopNavLinks.forEach(function(link) {
      link.addEventListener('click', function() {
        navbarDesktop.classList.remove('show');
        burger.classList.remove('clicked');
        body.classList.remove('overflow-hidden');
      });
});
  }, []);

  return (
   <footer className="site-footer">
  <div className="container">
    <div className="row">
      <div className="col-md-6  d-flex flex-column align-items-start justify-content-end">
        <div className="social">
          <ul>
            <li><a href="https://twitter.com/webicater" target="_blank" rel="noopener noreferrer">
                <svg width={27} height={22} viewBox="0 0 27 22" fill="#000" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.49094 21.9694C18.6796 21.9694 24.2524 13.5282 24.2524 6.20794C24.2524 5.96818 24.2524 5.7295 24.2362 5.4919C25.3204 4.70773 26.2562 3.73677 26.9999 2.62451C25.9889 3.07249 24.9164 3.36628 23.8183 3.49606C24.9747 2.80377 25.8402 1.7149 26.2537 0.432111C25.1663 1.07736 23.9767 1.5321 22.7361 1.77671C21.9009 0.888609 20.7963 0.300533 19.5932 0.103481C18.3901 -0.0935698 17.1556 0.11139 16.0807 0.686645C15.0058 1.2619 14.1505 2.17538 13.6471 3.28572C13.1436 4.39607 13.0202 5.64137 13.2959 6.82894C11.0935 6.71853 8.93892 6.1462 6.97208 5.1491C5.00523 4.152 3.27006 2.75243 1.8792 1.04123C1.17082 2.26072 0.953855 3.70436 1.27248 5.0782C1.59111 6.45204 2.42138 7.65279 3.59423 8.43598C2.71268 8.40985 1.85034 8.17204 1.08 7.74262C1.08 7.7653 1.08 7.78906 1.08 7.81282C1.08035 9.09177 1.52308 10.3312 2.3331 11.321C3.14312 12.3107 4.27056 12.9898 5.52419 13.243C4.70865 13.4655 3.85298 13.498 3.02291 13.3381C3.37689 14.4388 4.06605 15.4013 4.99399 16.0911C5.92193 16.7808 7.04225 17.1633 8.19826 17.185C6.2367 18.7267 3.81354 19.5636 1.31868 19.561C0.877934 19.5602 0.43762 19.5335 0 19.4811C2.53328 21.1068 5.4809 21.9691 8.49094 21.9651" />
                </svg>
              </a></li>
            <li>
              <a href="https://www.facebook.com/webicater" target="_blank" rel="noopener noreferrer">
                <svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg"><path d="m1.324.00097656c-.731 0-1.324.59296344-1.324 1.32392344v21.3507c0 .7319.593 1.3239 1.324 1.3239h11.676v-8.9994h-3v-2.9999h3v-1.9998c0-3.09884 2.235-4.99973 5-4.99973 1.325 0 2.168-.044 2.5 0l-.5 2.99982h-2c-1.5 0-2 .94994-2 1.99991v1.9998h4.273l-.773 2.9999h-3.5v8.9994h6.675c.733 0 1.325-.592 1.325-1.3239v-21.3507c0-.73096-.592-1.32392344-1.324-1.32392344z" /></svg></a>
            </li>
            <li>
              <a href="https://www.instagram.com/webicater/" target="_blank" rel="noopener noreferrer">
                <svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m23.9 7.10059c-.1-1.29981-.3-2.09961-.6-2.89942-.1048-.41894-.2647-.81152-.4938-1.19043-.2081-.34375-.4732-.67676-.8062-1.00976-.7-.7002-1.3-1.099613-2.1-1.400394-.8-.299805-1.6-.5-2.9-.59960944h-5c-3.30005 0-3.69995 0-4.90002.09960944-1.29993 0-2.19996.200195-3 .5-.69996.300781-1.40003.700194-2.09998 1.400394-.37158.37207-.65869.71484-.89111 1.07519-.205448.31836-.368167.64942-.508914 1.02442-.299927.7998-.5000004 1.70019-.5000004 3-.0181885.21875-.0330811.41113-.0452881.59961-.0235596.3623-.0369873.70996-.0445557 1.20312-.0101318.65234-.0101318 1.56058-.0101318 3.09768 0 3.2998 0 3.6992.0999756 4.8994.0999754 1.2998.3000484 2.1006.5999754 2.9004.200073.7998.600099 1.5 1.300049 2.2002.69995.6992 1.30005 1.0996 2.09998 1.3994.80004.2998 1.59997.5 2.90002.6006h5c1.6152 0 2.5356 0 3.1952-.0117.688-.0118 1.0922-.0372 1.7048-.0889.7245-.0557 1.2937-.1426 1.7942-.2598.3976-.0937.7517-.207 1.1058-.3398.1005-.0371.1994-.0772.2967-.1182.0826-.0351.1642-.0713.2446-.1094.5785-.2744 1.0982-.6357 1.5587-1.1728.7-.7002 1.1-1.2998 1.4-2.0996s.5-1.6006.6-2.9004c.1-1.2002.1-1.5996.1-4.8994 0-3.29983 0-3.70022-.1-4.90041zm-16.1002 4.89841c0 2.2959 1.90455 4.2002 4.2001 4.2002 2.2954 0 4.1999-1.9043 4.1999-4.2002 0-2.2949-1.9045-4.20017-4.1999-4.20017-2.29555 0-4.2001 1.90527-4.2001 4.20017zm-2 0c0 3.4004 2.80005 6.2012 6.2001 6.2012 3.3999 0 6.1999-2.8008 6.1999-6.2012 0-3.40037-2.8-6.20017-6.1999-6.20017-3.40005 0-6.2001 2.7998-6.2001 6.20017zm11.2002-6.39939c0 .80078.6 1.40137 1.4 1.40137.8001 0 1.4-.60059 1.4-1.40137 0-.79981-.5999-1.40039-1.4-1.40039-.8 0-1.4.60058-1.4 1.40039z" fillRule="evenodd" /></svg></a>
            </li>
          </ul>
        </div>
        <div className="copyright">
          <p>© 2023 Webicater. All rights reserved.</p>
          <p className="small">Webicater Technologies PVT Ltd is a company incorporated under the Company Act of Pakistan and is registered with the Securities and Exchange Commission of Pakistan (SECP).</p>
        </div>
      </div>
      <div className="col-md-6 d-flex mt-5 mt-md-0 flex-column justify-content-center  justify-content-md-end align-items-center align-items-md-end">
        <div className="heading mb-4">
          <h4 className="brand-heading">
            Certifications
          </h4>
          <h4 className="brand-heading  brand">&amp; Affiliations</h4>
        </div>
        <div className="widget_text box">
          <div className="textwidget custom-html-widget bages-wrap">
            <div className="badge">
              <img src="app/images/secp.svg" alt />
            </div>
            <div className="badge">
              <img src="app/images/certified-small-business.svg" alt className="src" />
            </div>
            <div className="badge">
              <img src="app/images/turing.svg" alt />
            </div>
            <div className="badge">
              <img src="app/images/smeda.svg" alt />
            </div>
          </div>
        </div>
        <div className="brand-legal mt-4">
        <div className="links">
          <Link to="/contact"> Careers</Link>
          <span className="ml-2 mr-2">|</span>
          <Link to="/privacy-policy"> Privacy Policy</Link>
          <span className="ml-2 mr-2">|</span>
          <Link to="/terms-of-service"> Terms of Service</Link>
        </div>
        </div>
      </div>
    </div>
  </div>
</footer>

  
  );
}

export default Footer;