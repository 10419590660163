import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
   
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };



  return (
    <header className={`header ${isScrolled ? 'header--scrolled' : ''}`}>
    <div className="container">
      <div className="row align-items-center no-wrap">
        <div className="logo" data-aos="flip-left"> 
        <Link to="/">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 890.63 667.17">
            <path d="M519.37,0V145.61h-148.11V0h148.11Z" />
            <path
                d="M740.15,298.33V149.11h-149.95c0,27.58,1.3,53.86-.25,79.97-4.12,69.18-57.67,128.31-123.41,137.91-7.11,1.04-14.2,1.56-21.22,1.56-7.02,0-14.11-.53-21.22-1.56-65.74-9.6-119.29-68.73-123.41-137.91-1.56-26.11-.25-52.39-.25-79.97H150.48v149.22H0v150.01c23.86,0,43.77-.04,63.69,0,95.14,.25,156.77,62.02,157.3,157.71,.11,20.47,.01,40.95,.01,61.11h150.48v-145.87c24.46,0,48.93,.01,73.83,.02h.01c24.9,0,49.37,0,73.83-.02v145.87h150.48c0-20.16-.1-40.63,.01-61.11,.53-95.69,62.16-157.47,157.3-157.71,19.91-.05,39.83,0,63.69,0v-150.01h-150.48Zm-369.17,165.65c-.97,18.77-.28,37.62-.32,56.45-24.24-.46-48.49-.75-72.73-1.45-82.75-2.36-144.02-62.6-147.23-145.25-.91-23.47-.14-47.01-.14-70.02,136.84-28.24,226.45,43.11,220.42,160.27Zm368.97-90.25c-3.21,82.65-64.48,142.89-147.23,145.25-24.23,.69-48.48,.98-72.73,1.45-.05-18.83,.65-37.68-.32-56.45-6.04-117.15,83.57-188.51,220.42-160.27,0,23.01,.77,46.55-.14,70.02Z" />
        </svg>
        </Link>
        </div>
        <div className="navbar-wrapper">
          <div className="navbar--desktop">
            <ul className="main" data-aos="fade-down">
              <li><Link to="/">Company</Link></li>
              <li><Link to="/what-we-do">What we do</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Connect</Link></li>
            </ul>
          </div>
          <div className="burger">
            <span />
          </div>
        </div>
      </div>
    </div>
    <div className="overlay" />
  </header>
  );
}

export default Header;