import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore, Navigation, Pagination, Autoplay } from 'swiper';

import 'swiper/css';
import shamrockFeatured from "../app/images/home-shamrock-featured.webp";
import shamrockMock from "../app/images/shamrock-malta-mock.webp";
import esquareFeatured from "../app/images/home-featured-desktop.webp";
import esquareMock from "../app/images/esquare-web-mock.webp";
import meatpointFeatured from "../app/images/meatpoint-brand-featured.webp";
import meatpointMock from "../app/images/meatpoint-tazijmeat-mock.webp";
import STLFeatured from "../app/images/STLFeatured.webp";
import STLMock from "../app/images/STLMock.webp";
// import onsiptechFeatured from "../app/images/onsiptech-callcenter-mock.webp";
// import onsiptechMock from "../app/images/onsiptech-callcenter-solutions-mock.webp";

const SliderWork = () => {
  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={30}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <div className="swiper-slide ">
          <div className="brand-service">
            <div className="brand-service-col-left">
              <img src={shamrockFeatured} alt="" className="src" />
            </div>
            <div className="brand-service-col-right">
              <div className="service-data color-shamrock">
                <div className="content">
                  <p>A modern and user-friendly website for Shamrock Apartments, a hospitality business based in Malta, Europe.</p>
                  <d className="mt-auto md:mt-12">
                    <a className="btn-slider transition-all" href="https://shamrockmalta.com" target="_blank">
                      <span>View Project</span>
                      <div className="fill-current ml-2 flex button-arrow-exit-done" style={{ width: 20 }}>
                        <svg id="bf2db89f-8079-469e-ad59-eed45e816ce7" data-name="Layer 1" viewBox="0 0 24 15" width="100%" height="100%">
                          <path d="M21.88,12l-7.52,6.24L15,19l9-7.52L15,4l-.65.76L21.88,11H0v1Z" transform="translate(0 -4)" style={{ fillRule: 'evenodd' }} />
                        </svg>
                      </div>
                    </a>
                  </d>
                </div>
              </div>
              <div className="service-sample">
                <img src={shamrockMock} alt="" className="src" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-slide ">
          <div className="brand-service">
            <div className="brand-service-col-left">
              <img src={esquareFeatured} alt="" className="src" />
            </div>
            <div className="brand-service-col-right">
              <div className="service-data color-esquare">
                <div className="content">
                  <p>A robust and user-friendly online store for an electronic retail business.</p>
                  <d className="mt-auto md:mt-12">
                    <a className="btn-slider transition-all" href="https://esquare.store" target="_blank">
                      <span>View Project</span>
                      <div className="fill-current ml-2 flex button-arrow-exit-done" style={{ width: 20 }}>
                        <svg id="bf2db89f-8079-469e-ad59-eed45e816ce7" data-name="Layer 1" viewBox="0 0 24 15" width="100%" height="100%">
                          <path d="M21.88,12l-7.52,6.24L15,19l9-7.52L15,4l-.65.76L21.88,11H0v1Z" transform="translate(0 -4)" style={{ fillRule: 'evenodd' }} />
                        </svg>
                      </div>
                    </a>
                  </d>
                </div>
              </div>
              <div className="service-sample">
                <img src={esquareMock} alt="" className="src" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-slide ">
          <div className="brand-service">
            <div className="brand-service-col-left">
              <img src={meatpointFeatured} alt="" className="src" />
            </div>
            <div className="brand-service-col-right">
              <div className="service-data color-meatpoint">
                <div className="content">
                  <p>An online platform for an established meat business in Pakistan.</p>
                  <d className="mt-auto md:mt-12">
                    <a className="btn-slider transition-all" href="https://meatpoint.pk" target="_blank">
                      <span>View Project</span>
                      <div className="fill-current ml-2 flex button-arrow-exit-done" style={{ width: 20 }}>
                        <svg id="bf2db89f-8079-469e-ad59-eed45e816ce7" data-name="Layer 1" viewBox="0 0 24 15" width="100%" height="100%">
                          <path d="M21.88,12l-7.52,6.24L15,19l9-7.52L15,4l-.65.76L21.88,11H0v1Z" transform="translate(0 -4)" style={{ fillRule: 'evenodd' }} />
                        </svg>
                      </div>
                    </a>
                  </d>
                </div>
              </div>
              <div className="service-sample">
                <img src={meatpointMock} alt="" className="src" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-slide ">
          <div className="brand-service">
            <div className="brand-service-col-left">
              <img src={STLFeatured} alt="" className="src" />
            </div>
            <div className="brand-service-col-right">
              <div className="service-data color-sergo">
                <div className="content">
                  <p>A business website ST. Louis Home Inspector - Inspected thousands since 2008</p>
                  <d className="mt-auto md:mt-12">
                    <a className="btn-slider transition-all" href="https://stlhomeinspector.com" target="_blank">
                      <span>View Project</span>
                      <div className="fill-current ml-2 flex button-arrow-exit-done" style={{ width: 20 }}>
                        <svg id="bf2db89f-8079-469e-ad59-eed45e816ce7" data-name="Layer 1" viewBox="0 0 24 15" width="100%" height="100%">
                          <path d="M21.88,12l-7.52,6.24L15,19l9-7.52L15,4l-.65.76L21.88,11H0v1Z" transform="translate(0 -4)" style={{ fillRule: 'evenodd' }} />
                        </svg>
                      </div>
                    </a>
                  </d>
                </div>
              </div>
              <div className="service-sample">
                <img src={STLMock} alt="" className="src" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      {/* <SwiperSlide>
        <div className="swiper-slide ">
          <div className="brand-service">
            <div className="brand-service-col-left">
              <img src={onsiptechFeatured} alt="" className="src" />
            </div>
            <div className="brand-service-col-right">
              <div className="service-data color-onsip">
                <div className="content">
                  <p>Creating a standout new story that creates new business possibilities.</p>
                  <d className="mt-auto md:mt-12">
                    <a className="btn-slider transition-all" href="https://onsiptechnologies.com" target="_blank">
                      <span>View Project</span>
                      <div className="fill-current ml-2 flex button-arrow-exit-done" style={{ width: 20 }}>
                        <svg id="bf2db89f-8079-469e-ad59-eed45e816ce7" data-name="Layer 1" viewBox="0 0 24 15" width="100%" height="100%">
                          <path d="M21.88,12l-7.52,6.24L15,19l9-7.52L15,4l-.65.76L21.88,11H0v1Z" transform="translate(0 -4)" style={{ fillRule: 'evenodd' }} />
                        </svg>
                      </div>
                    </a>
                  </d>
                </div>
              </div>
              <div className="service-sample">
                <img src={onsiptechMock} alt="" className="src" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide> */}
    </Swiper>
  );
};
export default SliderWork;
