
export default () => {
  return (
  <>
 <section className="section--services-list section-margin--l section-margin--none">
  <div className="container container-left">
    <div className="row">
      <div className="col-md-10 mr-auto ml-auto">
        <div className="heading">
          <h2 className="brand-heading dark" data-splitting>See what we</h2>
          <h2 className="brand-heading light" data-splitting>can do for you.</h2>
        </div>
      </div>
      <div className="col-md-10 mr-auto ml-auto">
        <div className="row container-services-list-items">
          <div className="col-md-6 mb-2 mb-md-5 scrollTo">
            <div className="services-list-item" data-aos="fade-up">
                <figure className="services-icon">
                  <img src="app/images/ideation.svg" alt />
                </figure>
                <h4>Ideation &amp; Strategy</h4>
                <p>Reach new business opportunities or test your product ideas</p>
                <p className="small">Business strategy solutions powered with market research, digital
                  transformation and business intelligence services</p>
            </div>
          </div>
          <div className="col-md-6 mb-2 mb-md-5 scrollTo">
            <div className="services-list-item" data-aos="fade-up">
                <figure className="services-icon">
                  <img src="app/images/product-design.svg" alt />
                </figure>
                <h4>Product Design</h4>
                <p>Create a beautiful digital product or polish your existing one</p>
                <p className="small">Product design and consulting solutions that will give your users a
                  digital journey to remember</p>
            </div>
          </div>
          <div className="col-md-6 mb-2 mb-md-0 scrollTo">
            <div className="services-list-item" data-aos="fade-up">
                <figure className="services-icon">
                  <img src="app/images/web-design.svg" alt />
                </figure>
                <h4>Web &amp; Mobile</h4>
                <p>Stay ahead of the game with tailor-made mobile and web apps</p>
                <p className="small">We offer web and mobile application development. Build cutting-edge
                  mobile apps or create a web app with our end-to-end web services</p>
            </div>
          </div>
          <div className="col-md-6 mb-2 mb-md-0 scrollTo">
            <div className="services-list-item" data-aos="fade-up">
                <figure className="services-icon">
                  <img src="app/images/ai.svg" alt />
                </figure>
                <h4>Artificial Intelligence</h4>
                <p>Automate your processes and get data-driven business insights</p>
                <p className="small">Answer your business challenges and enhance your digital products
                  with our Artificial Intelligence solutions</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  </>
  );
};