import React from 'react';
import esquareImage from '../app/images/esquare.svg';
import meatpointImage from '../app/images/meatpoint.svg';
import onsipImage from '../app/images/onsip.svg';
import shamrockMaltaImage from '../app/images/shamrock-malta.svg';
import thePuppyCutImage from '../app/images/thepuppycut.svg';
import stlImage from '../app/images/stl.svg';
import reasdevImage from '../app/images/reasdev.svg';
import mecartworksImage from '../app/images/mecartworks.svg';

const Clients = () => {
  return (
    <section className="brand-clients">
      <div className="container">
        <div className="col-md-10 ml-auto mr-auto">
          <div className="heading scrollTo">
            <h2 className="brand-heading dark" data-splitting>Friends we've made</h2>
            <h2 className="brand-heading brand" data-splitting>along the way.</h2>
          </div>
        </div>
        <div className="col-md-10 ml-auto mr-auto">
          <ul>
            <li data-aos="fade-up" data-aos-delay={0}>
              <img src={esquareImage} width={221} height={100} alt="Esquare Image" />
            </li>
            <li data-aos="fade-up" data-aos-delay={50}>
              <img src={meatpointImage} width={221} height={100} alt="Meatpoint Image" />
            </li>
            <li data-aos="fade-up" data-aos-delay={100}>
              <img src={onsipImage} width={221} height={100} alt="Onsip Image" />
            </li>
            <li data-aos="fade-up" data-aos-delay={150}>
              <img src={shamrockMaltaImage} width={221} height={100} alt="Shamrock Malta Image" />
            </li>
            <li data-aos="fade-up" data-aos-delay={200}>
              <img src={thePuppyCutImage} width={221} height={100} alt="The Puppy Cut Image" />
            </li>
            <li data-aos="fade-up" data-aos-delay={250}>
              <img src={stlImage} width={221} height={100} alt="STL Image" />
            </li>
            <li data-aos="fade-up" data-aos-delay={300}>
              <img src={reasdevImage} width={221} height={100} alt="Reasdev Image" />
            </li>
            <li data-aos="fade-up" data-aos-delay={350}>
              <img src={mecartworksImage} width={221} height={100} alt="Mecartworks Image" />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Clients;
