// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

export default () => {
  return (
    <Swiper
      spaceBetween={70}
      loop={'true'}
      autoplay={'true'}
      slidesPerView={'auto'}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      
      <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left color-1a">
                      <h2>Ideation &amp; Branding</h2>
                      <p>Bring your vision to life with our
                        ideation and branding services. Our
                        team of experts will help you develop a
                        clear and concise brand identity that
                        accurately reflects your
                        business values and goals
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote color-1b">
                      <h3>Plan ahead and maximise your chances of
                        success</h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/ideation-branding-service.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 color-1c">
                      <div className="content">
                        <p> you can expect a seamless and collaborative experience, resulting in
                          a clear and effective brand that captures the essence of your
                          business and connects with your target audience. Whether you are
                          starting a new business or
                          rebranding an existing one, we are here to help you create a brand
                          that truly stands out.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left color-2a">
                      <h2>Application Development</h2>
                      <p>
                        Bring your vision to life with our
                        ideation and branding services. Our
                        team of experts will help you develop a
                        clear and concise brand identity that
                        accurately reflects your
                        business values and goals.</p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote color-2b">
                      <h3>Enrich your
                        business processes with our custom app development
                        services</h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/application-development.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 color-2c">
                      <div className="content">
                        <p>Whether you need a custom app for your employees, a mobile app for
                          your customers, or a web based
                          application to streamline your operations, we have the
                          expertise to bring your vision to life. Our applications are
                          designed to be intuitive, user friendly, and scalable,
                          ensuring they meet your needs today and in the future.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left color-3a">
                      <h2>Andriod &amp; IOS</h2>
                      <p>
                        With our Android and iOS development
                        services, you can bring your app to the
                        worlds largest mobile platforms. Our
                        team has extensive experience in
                        creating top notch apps for both
                        platforms.</p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote color-3b">
                      <h3>Create native
                        applications for Android and iOS devices
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/andriod-and-ios.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 color-3c">
                      <div className="content">
                        <p> At Webicater, we help businesses leverage the power of mobile
                          technology with custom Android and iOS mobile app development
                          services. Whether you want to create a new mobile app or update an
                          existing one, our team has the expertise to deliver high-quality
                          results that meet your business needs and exceed your expectations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left color-4a">
                      <h2>Web Development</h2>
                      <p>
                        Take your business online with our web
                        development services. Our team is
                        experienced in creating fast,
                        user-friendly, and responsive websites
                        that deliver a seamless user experience.
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote color-4b">
                      <h3>Turn your ideas into outstanding digital products
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/web-development.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 color-4c">
                      <div className="content">
                        <p> We use the latest tools and technologies to build fast,
                          reliable, and visually appealing websites that provide a seamless
                          user experience. Our websites are designed to be user-friendly,
                          scalable, and customizable, ensuring they meet your needs today and
                          in the future.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left color-5a">
                      <h2>Graphics Design</h2>
                      <p>
                        From logos to product packaging, our
                        graphics design team will help you bring
                        your brand to life with visually stunning
                        designs that will leave a lasting impression on your customers
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 color-5b content-quote">
                      <h3>Discover the full
                        potential of product design
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/graphics-design.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 color-5c">
                      <div className="content">
                        <p> Our product and graphic design services are designed to be creative,
                          innovative, and effective. We take the time to understand your
                          business goals, target audience, and
                          industry to create customized design solutions that meet your
                          specific needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left bg-purple">
                      <h2>Search Engine Optimization</h2>
                      <p>
                        Boost your online visibility with our SEO
                        services. Our team will help you improve
                        your website's search engine rankings,
                        increase your online traffic, and drive
                        more sales to your business
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote bg-purple-light">
                      <h3>Proven increase in visibility, traffic, and revenue for your business
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/seo.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 bg-purple">
                      <div className="content">
                        <p> We use the latest tools and techniques to track and analyze your
                          websites performance, allowing us to make data driven decisions and
                          continuously improve your SEO results. Our approach is designed to
                          be transparent,
                          ethical, and effective, ensuring your website is optimized for both
                          search engines and users.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left bg-blue">
                      <h2>Digital Marketing</h2>
                      <p>
                        Reach your target audience with our
                        digital marketing services. Our team will
                        help you develop and implement a
                        customized digital marketing strategy
                        that will help you achieve your specific
                        business goals
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote bg-blue-light">
                      <h3>Connecting Your Business with Your Target
                        Audience
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/digital-marketing.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 bg-blue">
                      <div className="content">
                        <p>With webicater, you can expect a collaborative and
                          results driven digital marketing experience, resulting in increased
                          visibility, engagement, and revenue for your
                          business. Contact us today to learn more about our digital marketing
                          services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left bg-teal">
                      <h2>Social Media management</h2>
                      <p>
                        Maximize your online presence with our
                        social media management services.
                        Our team will help you manage your
                        social media accounts, engage with
                        your followers, and increase your online
                        reach
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote bg-teal-light">
                      <h3>Building Your Online Presence and
                        Engaging with Your Audience
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/social-media-management.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 bg-teal">
                      <div className="content">
                        <p>We use the latest tools and techniques to track and analyze your
                          websites performance, allowing us to make data driven decisions and
                          continuously improve your SEO results. Our approach is designed to
                          be transparent,
                          ethical, and effective, ensuring your website is optimized for both
                          search engines and users.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left bg-pink">
                      <h2>Cloud Services</h2>
                      <p>
                        Take your business to the next level with
                        our cloud services. Our team will help
                        you implement the latest cloud
                        technologies to improve your business
                        operations, increase efficiency, and
                        reduce costs
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote bg-pink-light">
                      <h3>We prioritize data security with the top-notch cloud services
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/cloud-services.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 bg-pink">
                      <div className="content">
                        <p>In todays fast paced business environment, its more
                          important than ever to have access to the latest
                          technology and tools to help drive success. Our cloud
                          services provide the scalability, cost effectiveness,
                          security, and increased productivity that your business needs to
                          succeed in the digital age
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left bg-purple">
                      <h2>Enterprise Resouce Planning (ERP Systems)</h2>
                      <p>
                        Streamline your business operations
                        with our ERP services. Our team will help
                        you implement a customized ERP
                        system that will help you improve your
                        business processes and increase
                        productivity
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote bg-purple-light">
                      <h3>Real time insights into your business operations, allowing you to make
                        wise decisions
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/enterprise-resouce-planning.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 bg-purple">
                      <div className="content">
                        <p>In todays competitive business environment, its more
                          important than ever to have access to the right tools and
                          technologies to help drive success. Our ERP solutions
                          provide the integration, scalability, increased efficiency, and
                          improved data visibility that your business needs to succeed in the
                          digital age.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left bg-blue">
                      <h2>School management Systems (SMS)</h2>
                      <p>
                        Improve the efficiency of your
                        educational institution with our school
                        management system services. Our SMS
                        streamline your administrative
                        processes and improve communication
                        with students, teachers, and parents.
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote bg-blue-light">
                      <h3>Streamline Your School Operations with Our School
                        Management System Solutions
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/school-management-system.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 bg-blue">
                      <div className="content">
                        <p>Our School Management System solutions provide the expert IT support,
                          integration, scalability, increased
                          efficiency, and improved data visibility that your school needs to
                          succeed in the digital age. Contact us today to learn more about how
                          our SMS solution can help s
                          treamline your school operations and support student success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left bg-pink ">
                      <h2>Blockchain Development</h2>
                      <p>
                        Stay ahead of the curve with our
                        blockchain services. Our team of
                        experts will help you integrate
                        blockchain technology into your
                        business operations and unlock its full
                        potential.</p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote bg-pink-light">
                      <h3>Harness the Power of Blockchain
                        Technology with Our Expert Development Services
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/blockchain-development.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 bg-pink">
                      <div className="content">
                        <p>We specialize in delivering innovative and secure
                          blockchain solutions to help businesses harness the full potential
                          of this revolutionary technology. Our team of
                          experienced blockchain developers and experts is
                          dedicated to creating customized solutions that meet your unique
                          business needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slider-services">
                <div className="brand-service">
                  <div className="brand-service-col-4">
                    <div className="brand-service-col-top-70 content-left color-1a">
                      <h2>Artificial Intelligence</h2>
                      <p>
                        We offer cuttingedge AI solutions that
                        help businesses transform their
                        operations and gain a competitive
                        edge. Our experienced AI developers
                        and data scientists work with you to
                        design a custom solution that meets
                        your unique needs
                      </p>
                    </div>
                    <div className="brand-service-col-bottom-30 content-quote color-1b">
                      <h3>Experience the Future of Business with Webicater's AI Services
                      </h3>
                    </div>
                  </div>
                  <div className="brand-service-col-8">
                    <div className="brand-service-col-top-70">
                      <img src="app/images/artificial-inteligence.webp" alt className="src" />
                    </div>
                    <div className="brand-service-col-bottom-30 color-1c">
                      <div className="content">
                        <p>Our AI services are tailored to meet the unique needs of businesses
                          in various industries. We provide a range of
                          services, including predictive analytics, natural language
                          processing, computer vision, and chatbots. Our team takes the time
                          to understand your business goals, data sources, and pain points to
                          design a custom AI solution that meets your requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
    </Swiper>
  );
};