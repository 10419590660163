// src/pages/TermsConditions.js

import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';


function TermsConditions() {
  useEffect(() => {
    // Call your JavaScript script here
     /*
  *
  * Animating  headers
  *
  */

 var h1s = document.querySelectorAll('.brand-heading');

 h1s.forEach(function(h1) {
 var words = h1.innerText.split(' ');
 var html = '';

 for (var i = 0; i < words.length; i++) {
     var word = words[i];
 
 var delay = (i + 1) * 50; // calculate delay for each div
     html += '<span data-aos="fade-up" data-aos-delay="' + delay + '">' + word + ' </span>';
 }

 h1.innerHTML = html.trim();
 });



/*
*
* Animating  brand logos
* 
*
*/
const lis = document.querySelectorAll('.brand-clients ul li');
lis.forEach((li, index) => {
li.setAttribute('data-aos', 'fade-up');
li.setAttribute('data-aos-delay', `${index * 50}`);
});
  }, []);
  return (
    <>
     <Helmet>
      <title>Terms of  Service | Webicater</title>
        <meta name="description" content="Webicater.com's terms of use govern your use of our site and include important information on liability, intellectual property, and user-generated content." />
      </Helmet>
    <section className="hero--section hero--section-inner hero--section-page d-flex align-items-center scrolled--smooth what-we-do bg-pink">
      <div className="container ">
        <div className="row ">
          <div className="col-md-10 col-lg-10 ml-auto mr-auto">
            <div className="hero--content rellax">
              <div className="banner-title">
                <h2 className="brand-heading">Terms of Service
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<div className="arrow-dual-colored">
  <div className="flex" data-aos="fade-up">
    <div className="arrow-top" style={{width: 2}} />
  </div>
  <div className="flex" data-aos="fade-up">
    <div className="arrow-bottom" style={{width: 2}} />
    <div className="arrow-tip">
      <div className="w-full fill-current text-gray-darkest"><svg id="b5fab9db-3c77-4b66-8b3a-b0edc797c4ee" data-name="Layer 1" viewBox="0 0 57.76 31.36" width="100%" height="100%">
          <path d="M57.42,1.38,56.5.46a1.55,1.55,0,0,0-2.2,0L29,25.77,3.69.46a1.55,1.55,0,0,0-2.2,0l-.92.92a1.55,1.55,0,0,0,0,2.2L27.89,30.91a1.57,1.57,0,0,0,2.2,0L57.4,3.58A1.53,1.53,0,0,0,57.42,1.38Z" transform="translate(-0.11)" />
        </svg></div>
    </div>
  </div>
</div>

 
<section className="connect-brand arrow-above-layer">
  <div className="container">
    <div className="row">
      <div className="col-md-10 ml-auto mr-auto">

<p className="mb-4">Access to and use of text files, images, and data on the www.webicater.com website are subject to the following terms and conditions:</p>
<p className="mb-4">
All materials on the site, including text, images, and data, are protected by copyright and other restrictions. Webicater retains all rights, including copyright, in the materials. Users may download the files for limited non-commercial, personal, and educational use only, or for fair use as defined in the United States copyright laws. Users must cite the author and source of the materials and include www.webicater.com.
</p>
<p className="mb-4">
Unauthorized use of trademarked, service marked, or any other materials, except as authorized in these terms and conditions, is strictly prohibited. Many of the trademarks, service marks, and logos displayed on www.webicater.com are registered and/or unregistered marks of Webicater. The trademarks of third parties may also be displayed on this site. Nothing contained in this site should be construed as granting any license or right to use any trademarks, service marks, or logos displayed on the site without the express written permission of Webicater or any third party that may own them.
</p>
<p className="mb-4">
All materials on the www.webicater.com website are provided "as is" without a warranty of any kind, either express or implied. Webicater assumes no responsibility and shall not be liable for any damages to the user's computer equipment or other property on account of your use of the site or your downloading of any materials from the site.
</p>
<p className="mb-4">
Webicater is not responsible for, nor does it endorse, the content of any off-site pages. Linking to any off-site pages or other sites is at the user's own risk.
</p>
<p className="mb-4">
Webicater may revise these terms and conditions by updating the posting located at www.webicater.com/terms-of-service. You are bound by any such revisions and should periodically visit this page to review the then-current terms and conditions.</p>
      </div>
    </div>
  </div>
</section>

     
      
    </>
  );
}

export default TermsConditions;