// src/pages/PrivacyPolicy.js

import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';


function PrivacyPolicy() {
  useEffect(() => {
    // Call your JavaScript script here
     /*
  *
  * Animating  headers
  *
  */

 var h1s = document.querySelectorAll('.brand-heading');

 h1s.forEach(function(h1) {
 var words = h1.innerText.split(' ');
 var html = '';

 for (var i = 0; i < words.length; i++) {
     var word = words[i];
 
 var delay = (i + 1) * 50; // calculate delay for each div
     html += '<span data-aos="fade-up" data-aos-delay="' + delay + '">' + word + ' </span>';
 }

 h1.innerHTML = html.trim();
 });



/*
*
* Animating  brand logos
* 
*
*/
const lis = document.querySelectorAll('.brand-clients ul li');
lis.forEach((li, index) => {
li.setAttribute('data-aos', 'fade-up');
li.setAttribute('data-aos-delay', `${index * 50}`);
});
  }, []);
  return (
    <>
      <Helmet>
      <title>Privacy Policy | Webicater</title>
      <meta name="description" content="Webicater.com's privacy policy page explains how we collect, use, and protect your personal information. We are committed to transparency and user privacy." />
      </Helmet>
    <section className="hero--section hero--section-innQer hero--section-page d-flex align-items-center scrolled--smooth what-we-do bg-pink">
      <div className="container ">
        <div className="row ">
          <div className="col-md-10 col-lg-10 ml-auto mr-auto">
            <div className="hero--content rellax">
              <div className="banner-title">
                <h2 className="brand-heading">Privacy Policy
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<div className="arrow-dual-colored">
  <div className="flex" data-aos="fade-up">
    <div className="arrow-top" style={{width: 2}} />
  </div>
  <div className="flex" data-aos="fade-up">
    <div className="arrow-bottom" style={{width: 2}} />
    <div className="arrow-tip">
      <div className="w-full fill-current text-gray-darkest"><svg id="b5fab9db-3c77-4b66-8b3a-b0edc797c4ee" data-name="Layer 1" viewBox="0 0 57.76 31.36" width="100%" height="100%">
          <path d="M57.42,1.38,56.5.46a1.55,1.55,0,0,0-2.2,0L29,25.77,3.69.46a1.55,1.55,0,0,0-2.2,0l-.92.92a1.55,1.55,0,0,0,0,2.2L27.89,30.91a1.57,1.57,0,0,0,2.2,0L57.4,3.58A1.53,1.53,0,0,0,57.42,1.38Z" transform="translate(-0.11)" />
        </svg></div>
    </div>
  </div>
</div>

 
<section className="connect-brand arrow-above-layer">
  <div className="container">
    <div className="row">
      <div className="col-md-10 ml-auto mr-auto">
        <p className="mb-4">This Privacy Policy applies to the website of Webicater.com and outlines the information we collect from you and how we use it. We reserve the right to modify this policy and any changes will take effect when posted on our website. We recommend checking this policy regularly. By accessing and using our website, you agree to this policy.</p>
            <h3 class="mb-3  mt-2 text-clamp-26-32">Information Collected</h3>

<p className="mb-4">We only collect personal information, such as your name, email or mailing address, when you voluntarily provide it to us through our website. The decision to provide such information is entirely up to you.
</p>
<p className="mb-4">We also collect non-personally identifiable aggregate information about visitors, such as their host name, IP address, browser type and version, date and time of the visit, and related site information, when pages on our website are requested through a web browser. We use this information for analytical and statistical purposes to improve your experience on our website.
</p>
<h3 class="mb-3  mt-2 text-clamp-26-32">Cookies</h3>
             
<p className="mb-4">We use a technology called “cookies” to collect information about your use of our website. Cookies are text files sent to a browser from a website and stored on a computer’s hard disk. They record information about a visitor’s visit, the pages visited, and any preferences set while browsing the site. We may use cookies to control access to certain features, customize content, or analyze user activity on our website.
</p>
<p className="mb-4">Cookies cannot retrieve data from your hard drive or obtain your email address, nor can they read cookies created by other websites. Your browser allows only the website that created the cookie to access it.
</p>
<p className="mb-4">If you are concerned about the use of cookies, you can set your browser options to notify you when a cookie is saved to your computer or disallow cookies. However, this may restrict your access to certain types of content or features on our website.
</p>      
      </div>
    </div>
  </div>
</section>

     
      
    </>
  );
}

export default PrivacyPolicy;