import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');



const app = (
  <App />

);

ReactDOM.createRoot(rootElement).render(app);