// src/pages/AboutPage.js

import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';

import About from '../components/About';

function AboutPage() {
  useEffect(() => {
    // Call your JavaScript script here
     /*
  *
  * Animating  headers
  *
  */

 var h1s = document.querySelectorAll('.brand-heading');

 h1s.forEach(function(h1) {
 var words = h1.innerText.split(' ');
 var html = '';

 for (var i = 0; i < words.length; i++) {
     var word = words[i];
 
 var delay = (i + 1) * 50; // calculate delay for each div
     html += '<span data-aos="fade-up" data-aos-delay="' + delay + '">' + word + ' </span>';
 }

 h1.innerHTML = html.trim();
 });



/*
*
* Animating  brand logos
* 
*
*/
const lis = document.querySelectorAll('.brand-clients ul li');
lis.forEach((li, index) => {
li.setAttribute('data-aos', 'fade-up');
li.setAttribute('data-aos-delay', `${index * 50}`);
});
  }, []);
  return (
    <>
     <Helmet>
        <title>About | Webicater</title>
        <meta name="description" content="We are development and design company. We build world-class digital products with a team of design, development and strategy experts." />
      </Helmet>
      <About />
    
    </>
  );
}

export default AboutPage;