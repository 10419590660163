// src/pages/ContactPage.js

import React, {useEffect} from 'react';
import ContactForm from '../components/ContactForm';
import { Helmet } from 'react-helmet';

function ContactPage() {
  useEffect(() => {
    // Call your JavaScript script here
     /*
  *
  * Animating  headers
  *
  */

 var h1s = document.querySelectorAll('.brand-heading');

 h1s.forEach(function(h1) {
 var words = h1.innerText.split(' ');
 var html = '';

 for (var i = 0; i < words.length; i++) {
     var word = words[i];
 
 var delay = (i + 1) * 50; // calculate delay for each div
     html += '<span data-aos="fade-up" data-aos-delay="' + delay + '">' + word + ' </span>';
 }

 h1.innerHTML = html.trim();
 });



/*
*
* Animating  brand logos
* 
*
*/

const lis = document.querySelectorAll('.brand-clients ul li');
lis.forEach((li, index) => {
li.setAttribute('data-aos', 'fade-up');
li.setAttribute('data-aos-delay', `${index * 50}`);
});
  }, []);
  return (
    <>
     <Helmet>
        <title>Not Found | Webicater</title>
        <meta name="description" content="The page you are looking for cannot be found." />
      </Helmet>
    <section className="hero--section hero--section-inner hero--section-page d-flex align-items-center scrolled--smooth what-we-do bg-salmon">
      <div className="container ">
        <div className="row ">
          <div className="col-md-10 col-lg-10 ml-auto mr-auto">
            <div className="hero--content rellax">
              <div className="banner-title">
                <h2 className="brand-heading ">The page you are
                </h2>
                <h2 className="brand-heading dark">looking for cannot be found.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<div className="arrow-dual-colored">
  <div className="flex" data-aos="fade-up">
    <div className="arrow-top" style={{width: 2}} />
  </div>
  <div className="flex" data-aos="fade-up">
    <div className="arrow-bottom" style={{width: 2}} />
    <div className="arrow-tip">
      <div className="w-full fill-current text-gray-darkest"><svg id="b5fab9db-3c77-4b66-8b3a-b0edc797c4ee" data-name="Layer 1" viewBox="0 0 57.76 31.36" width="100%" height="100%">
          <path d="M57.42,1.38,56.5.46a1.55,1.55,0,0,0-2.2,0L29,25.77,3.69.46a1.55,1.55,0,0,0-2.2,0l-.92.92a1.55,1.55,0,0,0,0,2.2L27.89,30.91a1.57,1.57,0,0,0,2.2,0L57.4,3.58A1.53,1.53,0,0,0,57.42,1.38Z" transform="translate(-0.11)" />
        </svg></div>
    </div>
  </div>
</div>

 
<section className="connect-brand arrow-above-layer">
  <div className="container">
    <div className="row">
      <div className="col-md-10 ml-auto mr-auto">
      <ContactForm />
      </div>
    </div>
  </div>
</section>

     
      
    </>
  );
}

export default ContactPage;