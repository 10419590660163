import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import Swiper from 'swiper';
// import AOS from 'aos';
import 'swiper/swiper-bundle.css';
import 'aos/dist/aos.css';
// import { useEffect } from 'react';
// compoenets

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrolToTop';

// pages
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import WhatWeDo from './pages/WhatWeDo';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import NotFound from './pages/NotFound';

function App() {
  useEffect(() => {
    // Call your JavaScript script here
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Router>
       <ScrollToTop>
        <Header />
        <Switch>
         
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/contact" component={ContactPage} />
          <Route  path="/what-we-do" component={WhatWeDo} />
          <Route  path="/terms-of-service" component={TermsConditions} />
          <Route  path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="*" component={NotFound}/>
           
        </Switch>
        <Footer />
        </ScrollToTop>
    </Router>
  );
}

export default App;




